import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"
import styled from "styled-components"
import { Link } from "gatsby"

import { H1, H2, P } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"

import imageMagasin from "../images/magasin.jpg"
import imageTraiteur from "../images/traiteur.jpg"
import imageVins from "../images/vins.jpg"
import imagePanier from "../images/panier.jpg"

import Header from "../components/header"
import Hero from "../components/hero"

const Button = styled.a`
  margin: 20px 0;
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 4px;
`

const Page = () => (
  <div>
    <SEO title="Raineri" />
    <Header/>

    <H1 className="text-center">Chef de cuisine (H/F)</H1>

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <P>À notre aimable clientèle,</P>
          <P>Pour notre établissement spécialisé en cuisine italienne, nous cherchons un chef de cuisine (H/F) capable de gérer et préparer nos plats typiques italiens à emporter.</P>
          <P>Vous êtes responsable de toute la cuisine ainsi que de son entretien.</P>
          <P>Vous servirez occasionnellement au service traiteur (en cas de besoin).</P>
          <P>Votre horaire de travail temps plein 38 h sera du mardi au samedi en journée.</P>
          <P>Contrat à durée indéterminée</P>
          <P>Date de début : 02/06/2020</P>
          <P>Veuillez envoyer votre CV et lettre de motivation à l'adresse suivante : <a href="mailto:iseppi.michele@gmail.com">iseppi.michele@gmail.com</a></P>
        </div>
      </div>
    </Section>
  </div>
)

export default Page
